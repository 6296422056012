import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import nft from '../assets/coin.png';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class Story extends Component {

    render() {
        return (

            <div class="boxWrap2Story2">

                <div class="about">
                <div class="conT">WHAT IS $LEAP?</div>
                    <div class="storyCon">
                        <p>Leap is a Rewards Token Within the FrogChain Ecosystem. LEAP is Used for Various Reward Payout Methods Including NFT Staking and Holder Rewards. LEAP has a 0% Buy Tax and 5% Sell Tax - FrogChain will Use this Tax to Initiate Random Buyback and Burns of the Native $FROG Token to Help Reduce the Total Supply.</p>
                      </div>
                    <img src={nft} />

                </div>

            </div>
        )
    }
}

export default Story;

