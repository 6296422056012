/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-undef */
import React, { useEffect, useMemo, useState } from 'react';

import './App.css';
import Story from "./components/story";
import Footer from './components/footer';
import navbar from './assets/navigation-bar.png';
import wrong from './assets/wrong.png';
import banner from './assets/topImage.png';
import logoUp from './assets/logo.png';
import os from './assets/os.png';
import frog from './assets/leapingFrog.png';
import twitter from './assets/twitter.png';
import telegram from './assets/telegram.png';
import Buy from './components/buy';
import Benefits from './components/benefits';
import { ethers, providers } from 'ethers';

import { useNotification } from "@web3uikit/core";
import Web3Modal from "web3modal";
import { Route, Routes } from 'react-router-dom';


const opensea = () => {
  window.open("https://opensea.io/collection/frog-chain");
}

const home = () => {
  window.open("https://frogchain.io/");
}

const mintSite = () => {
  window.open("https://mint.frogchain.io");
}

const buy = () => {
  window.open("https://www.dextools.io/app/en/bnb/pair-explorer/0x55a6f55276b307e1c68b3dd73213b63e47694997");
}

const stakeSite = () => {
  window.open("https://nftstake.frogchain.io");
}

const gameSite = () => {
  window.open("https://battle.frogchain.io/");
}

const tweet = () => {
  window.open("https://twitter.com/frogtokenbsc");
}

const tg = () => {
  window.open("https://t.me/frogtokenbsc");
}

const contractAddress = '0xc459F78CAfbCb0de418742471Ac04D98B1B78837'; // Replace with the actual contract address
const contractABI = [
	{
		"inputs": [],
		"name": "claimRewards",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "frogTokenWithdrawal",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "leapTokenWithdrawal",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_frogTokenContract",
				"type": "address"
			}
		],
		"name": "setFrogContract",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_leapTokenContract",
				"type": "address"
			}
		],
		"name": "setleapContract",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_rewardsAmount",
				"type": "uint256"
			}
		],
		"name": "setRewardsAmount",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_rewardsCircle",
				"type": "uint256"
			}
		],
		"name": "setRewardsCircle",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_rewardsRate",
				"type": "uint256"
			}
		],
		"name": "setRewardsRate",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [],
		"name": "withdrawal",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "_token_Frog",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "_token_Leap",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "claimCount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "frog",
		"outputs": [
			{
				"internalType": "contract IBEP20",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "initiatedTime",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "leap",
		"outputs": [
			{
				"internalType": "contract IERC20",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "rewardsAmount",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "rewardsCircle",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "rewardsRate",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "tokensHoldingDuration",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	}
];

const App = () => {

  const [_navbarOpen, set_navbarOpen] = useState(0)
  const [_closeWindow2, set_closeWindow2] = useState(false)
  const notification = useNotification();

  const claimRewards = async () => {

    try {

      const web3Modal = new Web3Modal();
      const connection = await web3Modal.connect();
      const provider = new ethers.providers.Web3Provider(connection);
      const signer = provider.getSigner();

      const contract = new ethers.Contract(contractAddress, contractABI, signer);

      try {

        const transaction = await contract.claimRewards();
        await transaction.wait();

        notification({
          type: 'success',
          title: 'Claimed Successfully',
          position: 'topR'
        });
      } catch (error) {
        notification({
          type: 'error',
          title: 'Transaction Error',
          message: error.message,
          position: 'topR'
        });
      }


      // You can add additional logic or UI updates here if needed
      console.log('Rewards claimed successfully!');
    } catch (error) {
      console.error('Error claiming rewards:', error);
      // Handle errors or show a user-friendly message
    }
  };


  async function closeWindow2() {
    set_closeWindow2(false)

    //this.setState({ _navbarOpen: 0 });
  }

  async function closeNav() {
    set_navbarOpen(0);
    //this.setState({ _navbarOpen: 0 });
  }

  async function navbarOpen() {
    set_navbarOpen(1);
    //this.setState({ _navbarOpen: 0 });
  }


  return (
    <div class="allWrap">
      <div class="light">
        <div class="cont">

          {_navbarOpen < 1 ?
            (<div class="miniBarMain">
              <div class="title" onClick={home}><img class="logoUp" src={logoUp} /><div>FROG CHAIN</div></div>

              <div class="navbarMain"><img class="navbar" onClick={navbarOpen} src={navbar} /></div>
            </div>) :
            (<div class="miniBarMain">
              <div class="title" onClick={home}><img class="logoUp" src={logoUp} /><div>FROG CHAIN</div></div>

              <div class="navbarMain"><img class="navbar" onClick={closeNav} src={wrong} /></div>
            </div>)}

          <div class="headers">

            <div class="h1">

              <div class="title" onClick={home}><img class="logoUp" src={logoUp} /><div>FROG CHAIN</div></div>

              <div class="connect">
                <div class="connect">
                  <div id="fontSize" onClick={home}>Home</div>
                  <div id="fontSize" onClick={mintSite}>Mint</div>
                  <div id="fontSize" onClick={stakeSite}>Staking</div>
                  <div id="fontSize" onClick={gameSite}>Battlefield</div>
                </div>

              </div>

              <div class="right">

                <div class="icons">
                  <img onClick={opensea} src={os} />
                  <img onClick={tweet} src={twitter} />
                  <img onClick={tg} src={telegram} />
                </div>

                <div class="connect2">
                  
                  <button class="button-33" onClick={buy}>
                    Leap Chart
                  </button>
                </div>

              </div>

            </div>

          </div>

          {_navbarOpen > 0 ?
            (<div class="littleNav">
              <div id="fontSize" onClick={home}>Home</div>
              <div id="fontSize" onClick={mintSite}>Mint</div>
              <div id="fontSize" onClick={stakeSite}>Staking</div>
              <div id="fontSize" onClick={gameSite}>Battlefield</div>

              <div class="icons">
                <img onClick={opensea} src={os} />
                <img onClick={tweet} src={twitter} />
                <img onClick={tg} src={telegram} />
              </div>

            </div>) : null}

          <div class="introduction">
            <img class="title2" src={banner}></img>
            <div class="subT">Transforming Sustainability And Trust Within The FrogChain Ecosystem</div>
          </div>


        </div>

        <Story />


        <div class="buyMain2">

          <div class="aboutA">
            <div class="conT">REWARDS</div>
            <div class="buyCon">
              <p>Unlock earnings through $LEAP by embracing the power of holding $FROG.
                <br />Seize this opportunity and don't let it slip away!</p>
            </div>
            <button class="button-82-pushable" role="button" onClick={claimRewards}>
              <span class="button-82-shadow"></span>
              <span class="button-82-edge"></span>
              <span class="button-82-front text">
                CLAIM
              </span>
            </button>
          </div>

        </div>


        <Benefits />

        <img class="leapFrog" src={frog} />

        <Buy />

        <Footer />

      </div >
    </div >
  )

}
export default App;
