import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import img1 from '../assets/speedometer.png';
import img2 from '../assets/insurance.png';
import img3 from '../assets/key.png';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class Benefits extends Component {

    render() {
        return (

            <div class="benefitsMain">
                <div class="conT">BENEFITS</div>

                <div class="about2">

                    <div class="box" >
                        <div class="boxH">Efficiency and Sustainability</div>
                        <img src={img1} />
                        <p class="boxP">Efficiancy and Sustainability to the FrogChain Ecosystem</p>
                    </div>

                    <div class="box" >
                        <div class="boxH">Security and Transparency</div>
                        <img src={img2} />
                        <p class="boxP">Smart Contracts Battle Tested to Ensure Maximum Security</p>
                    </div>

                    <div class="box" >
                        <div class="boxH">Key to Passive Income</div>
                        <img src={img3} />
                        <p class="boxP">Passive Income to Diamond Holders and NFT Stakers</p>
                    </div>

                </div>

            </div>
        )
    }
}

export default Benefits;

