import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

const buy = () => {
	window.open("https://pancakeswap.finance/swap?outputCurrency=0x2c9a336c9c0C0FF42e1e2a346dB5787303655680&inputCurrency=BNB");
}

class Buy extends Component {

    render() {
        return (

            <div class="buyMain">

            <div class="aboutA">
            <div class="conT">Information</div>
                <div class="buyCon">
                    <p>Contract Address: 0x2c9a336c9c0c.....3655680</p>
                 </div>
                <button class="button-85" onClick={buy}>BUY HERE</button>
            </div>

        </div>
        )
    }
}

export default Buy;

